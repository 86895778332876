import React, {Fragment, useEffect, useState} from "react";
import Timeline from "../tracking/Timeline";
import loaderImage from '../../assets/img/hubtel_loader.gif';
import {prepareOrderPaymentTracking, trackerStatus} from "../../misc/helpers";

const TrackModel = ({data}) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

    }, []);

    let a = prepareOrderPaymentTracking(data.orderDetails, data.payment)
    let status = trackerStatus(data.orderDetails, data.payment)

    return (
        <Fragment>
            <div className="modal fade" id="trackStatus" tabIndex="-1" role="dialog" aria-labelledby=""
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{minHeight: 400}}>
                        {isLoading ?
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <div className="px-4 py-4">
                                    <div
                                        className="d-flex justify-content-between align-items-baseline border-bottom pb-3">
                                        <div className="d-flex align-items-center">
                                            <h5 className="font-weight-bold mb-0">Status</h5>
                                        </div>

                                        <span className={"status status-" + status}>
                                      <span className="badge">
                                      </span>
                                    </span>
                                    </div>
                                    <div className="mt-4">
                                        <div className="timeline-block">
                                            {
                                                a.map((item, index) => {
                                                    return (<Timeline key={index} data={item}/>)
                                                })
                                            }
                                        </div>
                                    </div>


                                </div>

                            </div> : <div className="text-center align-middle py-5">
                                <img src={loaderImage} width={50} className="img-fluid"/>
                            </div>}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default TrackModel;
