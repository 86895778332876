import React from "react";
import EmptyStateImg from '../assets/img/no-search-data.svg';


const EmptyState = () => {
    return (
        <>
            <div className={"wrapper main-wrapper"} style={{marginTop: 200}}>
                <div className="row justify-content-center">
                    <div className={"col-md-6 text-center align-self-center"}>
                        <ul className="list-unstyled">
                            <li>
                                <img style={{height: 200}} src={EmptyStateImg} className={"img-fluid"}/>
                            </li>
                            <li className={"pt-4"}>
                                <h2>No content found</h2>
                            </li>
                            <li>
                                <p>The order you are looking for was not found.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmptyState;
