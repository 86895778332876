import React from "react";
import {timeFormatter} from "../network/models/quick_models";
import Block from "./Block";
import {getCurrentState, getDeliveryStateTime, isActive} from "../misc/helpers";
import DataNotAvailableBlock from "./DataNotAvailableBlock";

const Section = ({data}) => {
    let getIsActive = isActive(data.statuses.data.DeliveryStates, data.item.status)
    let time = getDeliveryStateTime(data.statuses.data.DeliveryStates, data.item.status)
    let state = getCurrentState(data.statuses.data.DeliveryStates, data.item.status)


    const displayStatus = () => {
        if (getIsActive) {
            return "isActive"
        } else {
            return "isInActive"
        }
    }

    return (
        <>
            <div className={"line " + displayStatus() + " " + displayStatus() + "-text"} id={data.item.status}>
                <section className="year">
                    <section>
                        {/*.isActive-step-0::after*/}
                        <ul id={"step-" + data.index} className={displayStatus() + "-step-" + data.index}>
                            <li className="font-weight-bold">
                                {data.item.title}
                            </li>
                            {
                                !(getIsActive) ? <li>
                                    <DataNotAvailableBlock/>
                                </li> : <>
                                    <li>
                                        <small
                                            className="muted">{timeFormatter(time)}</small>
                                    </li>
                                    <li><p>{data.item.subTitle}</p></li>
                                    <li>
                                        {

                                            state === "Assigned" ?
                                                <Block data={{
                                                    title: data.orderResponse.data.BusinessName,
                                                    subTitle: data.orderResponse.data.BusinessMobileNumber,
                                                    image: data.orderResponse.data.LogoUrl
                                                }}/>
                                                : null
                                        }
                                        {

                                            state === "Accepted" ?
                                                <Block data={{
                                                    title: data.statuses.data.Agent.Name,
                                                    subTitle: data.statuses.data.Agent.Phone,
                                                    image: ""
                                                }}/>
                                                : null
                                        }
                                    </li>
                                </>
                            }
                        </ul>
                    </section>
                </section>
            </div>
        </>
    );
};

export default Section;
