import React, {useState} from "react";
import OtpModal from "./Otp";
import HLoader from "../HLoader";

const PointRedemption = ({data}) => {
    const [viewState, setViewState] = useState("message")

    const displayViewState = () => {

        switch (viewState.toLowerCase()) {
            case "message":
                return (
                    <>
                        <div className="modal-body">
                            <div className="px-4 py-4">
                                <h5 className="font-weight-bold">Redeem Point</h5>

                                <div className="mt-4">
                                    <p>You are about to redeem a point of <span>{data.CustomerReward + " gpts"}</span>
                                    </p>
                                </div>
                                <div className="mt-4 text-right">
                                    <button onClick={() => {
                                        setViewState("otp")
                                    }}
                                            className="btn btn-primary">Okay
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "otp":
                return (
                    <OtpModal data={{
                        order: data,
                        selectedIssue: "",
                        feedback: {rating: "", comment: ""},
                        type: "redeempoint"
                    }}/>
                );
            default:
                return (<HLoader/>);
        }

    }

    return (
        <>
            <div className="modal fade" id="redeemPoint" tabIndex="-1" role="dialog" aria-labelledby="savedOrderLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{minHeight: 200}}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        {
                            displayViewState()
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PointRedemption;
