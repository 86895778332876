import moment from "moment";

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9tb2JpbGVwaG9uZSI6IjIzMzU1MjkxNjYwMyIsImp0aSI6ImU4NzI5MWIzLWQ3ODItNDVhNC1hN2FiLWI0NmZjM2U5ZDMxNSIsIm5iZiI6MTU3MTg1MDAxMywiZXhwIjoxNjAzMzg2MDEzLCJpc3MiOiJodHRwOi8vaHVidGVsLmNvbSIsImF1ZCI6Imh0dHA6Ly9odWJ0ZWwuY29tIn0.uxzLixj2LaS_suIPmIWex80SBt_AHkk1heu75WO0xD4";

const DeliveryStatusResponse = {
    error: false,
    developer_message: '',
    data: {
        Id: '',
        CreatedAt: '',
        Agent: {
            Name: '',
            Phone: '',
            Id: ''
        },
        Commission: 0.0,
        Status: '',
        OrderToken: '',
        Note: '',
        CustomerNumber: '',
        Distance: '',
        VerificationCode: '',
        Duration: '',
        PickUpLocation: {
            Name: '',
            Latitude: '',
            Longitude: ''
        },
        DropOffLocation: {
            Name: '',
            Latitude: '',
            Longitude: ''
        },
        DeliveryStates: [
            {
                State: '',
                Note: '',
                CreatedAt: ''
            }
        ]
    },
    status: 200,
    message: 'success'
};

const orderDeliveryTrackingSteps = (actor, businessName, verificationCode) => {
    return [
        {
            title: "Preparing Order",
            subTitle: "Your order has been confirmed! Sit back, relax while " + businessName + " prepares your order.",
            phone: "",
            actorName: "",
            status: "Pending",
            time: "",
            isActive: false
        },
        {
            title: "Order Assigned",
            subTitle: "Your order has been assigned to " + actor + ". He is on his way to pickup your order.",
            phone: "",
            actorName: "",
            status: "Accepted",
            time: "",
            isActive: false
        },
        {
            title: "Order Picked up",
            subTitle: "Your order has been picked up and it's on it's way to you. " +
                "Please give this code [" + verificationCode + "] to " + actor + " only when you have received the order.",
            phone: "",
            actorName: "",
            status: "PickedUp",
            time: "",
            isActive: false
        },
        {
            title: "Order Delivered",
            subTitle: "Your order has arrived!  Thank you for your purchase.",
            phone: "",
            actorName: "",
            status: "Confirmed",
            time: "",
            isActive: false
        }
    ];
}


export const dateFormatter = (rawDate) => {
    return moment(rawDate).format('MMMM Do YYYY, h:mm:ss a');
};

export const timeFormatter = (rawDate) => {
    return moment(rawDate).format('MMMM Do YYYY, h:mm a');
};

const paymentStatusResponse = {
    error: false,
    developer_message: "",
    data: {
        StartDate: "",
        TransactionStatus: "",
        NetworkTransactionId: "",
        CheckoutId: "",
        PaymentMethod: "",
        ClientReference: "",
        TransactionAmount: 0.0,
        Fee: 0.0,
        AmountAfterFees: 0.0,
        CardSchemeName: "",
        CardNumber: "",
        MobileNumber: null,
        MobileChannelName: null,
        ProviderDescription: "",
        AuthorizationCode: null
    },
    status: 200,
    message: "success"
}

const signInResponse = {
    error: false,
    developer_message: "",
    data: {
        requestId: "",
        otpPrefix: "",
        require2fa: false
    },
    status: 200,
    message: ""
}

const orderResponse = {
    error: false,
    developer_message: '',
    data: {
        id: 'c2c2e335be4041df986048d797ca47c8',
        paymentRequest: {
            channel: 'mtn-gh',
            customerMsisdn: '233242686363',
            primaryCallbackUrl: '',
            token: '',
            feesOnCustomer: true,
            amount: 81.81,
            description: '',
            clientReference: null,
            orderId: 'c2c2e335be4041df986048d797ca47c8',
            currency: 'GHS',
            fee: 0.81,
            deliveryFee: 0,
            businessName: 'MTN Mobile Money',
            receiptNumber: '58591-201022-095900',
            orderNumber: '58591-201022-095900',
            paymentType: 'mobilemoney',
            businessId: 'NH1491f3f0367d43838a6d7652e35be7c3',
            hubtelReference: '1754FC073C810869993',
            amountTendered: 0,
            customerMobileNumber: '233242686363',
            customerName: 'Susana Mantey',
            isRefund: false,
            balance: 0,
            createdAt: null,
            updatedAt: null,
            createdBy: null,
            updatedBy: null
        },
        integrationChannel: 'Mobile',
        orderDate: '2020-10-22T10:00:14.0082014Z',
        orderNumber: '58591-201022-095900',
        note: null,
        description: null,
        status: 'Paid',
        assignedTo: null,
        employeeId: null,
        employeeName: null,
        customerMobileNumber: '233242686363',
        customerName: 'Susana Mantey',
        branchId: 'd8c06e89dcc1426abf0afcf97a581d34',
        branchName: 'MTN Mobile Money',
        discountRate: null,
        discountAmount: 0,
        subtotal: 81,
        totalAmountDue: 81.81,
        amountPaid: 81.81,
        amountRefunded: 0,
        location: null,
        currency: 'GHS',
        orderItems: [
            {
                itemId: '3e0841e70afc42fb97d13d19abd36384',
                orderId: null,
                name: 'Send MTN Mobile Money to 233246112530 (Sarahakua Mensah)',
                variantName: null,
                quantity: 1,
                unitPrice: 81,
                discountRate: 0,
                discountAmount: 0,
                note: '',
                isReturned: false,
                serviceRequestId: 'd511d206301540cda3b6dec365bd4c39',
                id: null,
                businessId: 'NH1491f3f0367d43838a6d7652e35be7c3',
                category: null,
                supplier: null,
                createdAt: '0001-01-01T00:00:00',
                updatedAt: null,
                createdBy: null,
                updatedBy: null
            }
        ],
        invoiceAdditions: [],
        consumerRating: 0,
        consumerFeedback: null,
        isFulfilled: true,
        customerEmail: null,
        businessEmail: 'support@hubtel.com',
        businessMobileNumber: '0307008000',
        businessName: 'MTN Mobile Money',
        fcmCustomer: null,
        fcmDevice: null,
        deliveryFee: 0,
        hasDelivery: false,
        customerReward: 81.81,
        senderId: 'HubtelMoMo',
        branchEmail: 'alex@alexbram.com',
        branchPhoneNumber: null,
        returnUrl: null,
        cancellationUrl: '',
        logoUrl: '',
        deliveryLocationName: null,
        isRecurring: false,
        recurringInvoiceId: null,
        isProgrammableService: true,
        businessId: 'NH1491f3f0367d43838a6d7652e35be7c3',
        createdAt: '2020-10-22T10:00:14.0082014Z',
        updatedAt: null,
        createdBy: null,
        updatedBy: null,
        redeemRewardStatus: null,
        payment: {
            paymentType: 'mobilemoney',
            orderId: 'c2c2e335be4041df986048d797ca47c8',
            refundTransactionId: null,
            refundCompletedDate: null,
            businessId: '',
            refundRequestedDate: null,
            balance: 0,
            amountRefunded: 0,
            id: '',
            chargeCustomer: true,
            posDeviceId: null,
            employeeName: null,
            branchId: 'd8c06e89dcc1426abf0afcf97a581d34',
            refundDestination: null,
            transactionId: '1754FC073C810869993',
            customerName: 'Susana Mantey',
            charges: 0.81,
            isRefund: false,
            providerDescription: '',
            card: null,
            statusCode: '0000',
            note: null,
            scheme: null,
            momoPhoneNumber: '233242686363',
            description: '',
            mid: null,
            refundStatus: null,
            refundDestinationType: null,
            posDeviceType: null,
            amountPaid: 81.81,
            isSuccessful: true,
            clientReference: '...',
            customerMobileNumber: '233242686363',
            currency: 'GHS',
            momoChannel: 'mtn-gh',
            receiptNumber: '58591-201022-095900',
            amountAfterCharges: 81,
            amountTendered: 0,
            refundRequestedBy: null,
            branchName: 'MTN Mobile Money',
            employeeId: null,
            isRecurring: false,
            recurringInvoiceId: null,
            deliveryFee: 0,
            location: null,
            paymentDate: '2020-10-22T10:00:14.0082014Z'
        }
    },
    status: 200,
    message: 'success'
};

const ratings = [
    "Bad",
    "Okay",
    "Great"
];

const orderTrackingStatusBody = {
    data: [
        {
            title: "Creating your order",
            info: "",
            subTitle: "Your order of GHS5.00 has been created successfully.",
            image: "",
            message: "",
            deactivate: false,
            showBottom: false,
            text: "",
            status: "success"
        },
        {
            title: "Processing your payments",
            info: "",
            subTitle: "",
            image: "",
            message: "",
            deactivate: false,
            showBottom: true,
            text: "",
            status: "failed"
        },
        {
            title: "Completing your purchase",
            info: "",
            subTitle: "",
            image: "",
            message: "",
            deactivate: false,
            showBottom: true,
            text: "",
            status: "success"
        }
    ]
};
const customerFeedbackRequester = {
    authToken: "",
    busnessId: "",
    customerImageUrl: "",
    rate: 0,
    feedback: "",
    orderId: ""
}

export const IssuesList = [
    "Excellent! I love the service",
    "I was debited but the service was not provided.",
    "Transaction was successful but I didn't receive the right item(s)/service.",
];

export const FailedIssuesList = [
    "Excellent! I love the service",
    "Transaction failed but I was debited"
];

const deliveryStatusRequester = {
    "orderId": "",
    "authToken": authToken
};

const orderRequest = {
    "orderId": "",
    "businessId": "",
    "authToken": authToken
};


const pointRedemptionRequester = {
    authToken: "",
    orderId: ""
};

const Payment = {
    Status: true,
    Message: "",
    Code: 2000,
    Data: {
        PaymentType: "",
        OrderId: "",
        MomoPhoneNumber: "",
        MomoChannel: "",
        MomoToken: null,
        TransactionId: "",
        ExternalTransactionId: "",
        AmountAfterCharges: 0.0,
        Charges: 0.0,
        ChargeCustomer: false,
        AmountPaid: 0.0,
        PaymentDate: "",
        Note: null,
        Description: "",
        PosDeviceId: null,
        PosDeviceType: null,
        EmployeeId: "",
        EmployeeName: "",
        CustomerMobileNumber: "",
        CustomerName: null,
        BranchId: "",
        BranchName: "",
        IsRefund: false,
        RefundTransactionId: null,
        RefundRequestedDate: null,
        RefundCompletedDate: null,
        RefundRequestedBy: null,
        RefundDestinationType: null,
        RefundDestination: null,
        AmountRefunded: 0.0,
        RefundStatus: null,
        IsSuccessful: false,
        ReceiptNumber: "",
        Location: null,
        Currency: "",
        Order: null,
        Scheme: null,
        Card: null,
        Tid: null,
        Authorization: null,
        Mid: null,
        CardTransactionId: null,
        AmountTendered: 0.0,
        Balance: 0.0,
        ClientReference: "",
        ProviderDescription: "",
        StatusCode: "",
        IsRecurring: false,
        RecurringInvoiceId: null,
        DeliveryFee: 0.0,
        FineractSavingsAccountId: 0,
        IsSyncedToFineract: null,
        SyncToFineractBatchId: null,
        PaymentProcessor: null,
        Id: "",
        BusinessId: "",
        CreatedAt: "",
        UpdatedAt: null,
        CreatedBy: null,
        UpdatedBy: null
    }
}

const otpVerificationRequest = {
    msisdn: "",
    requestId: "",
    otpCode: ""
};

const verifiedOtpResponse = {
    "error": false,
    "developer_message": "",
    "data": {
        mobileNumber: "",
        token: "",
        hasSetPin: false
    },
    "status": 200,
    "message": ""
}

export default {
    deliveryResponse: DeliveryStatusResponse,
    deliveryStatusRequester: deliveryStatusRequester,
    orderRequest: orderRequest,
    orderResponse: orderResponse,
    paymentStatusResponse: paymentStatusResponse,
    orderTrackingBody: orderTrackingStatusBody,
    issuesList: IssuesList,
    failedIssuesList: FailedIssuesList,
    signInResponse: signInResponse,
    otpVerificationRequest: otpVerificationRequest,
    verifiedOtpResponse: verifiedOtpResponse,
    ratings: ratings,
    customerFeedbackRequester: customerFeedbackRequester,
    pointRedemptionRequester: pointRedemptionRequester,
    payment: Payment,
    orderDeliveryTrackingSteps: orderDeliveryTrackingSteps,
    c: authToken
};
