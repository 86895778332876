import React, {Fragment} from "react";
import * as formatter from "../../misc/number_formatter";

const InvoiceAdditionItem = ({data}) => {
    const showInvoiceAdditionsComputedValue = (isFlate) => {
        if (isFlate) {
            return data.figure
        } else {
            return "(" + data.figure + "%)"
        }
    }

    return (
        <Fragment>
            <tr className="border-bottom">
                <td className="text-muted w-100">{data.name} {showInvoiceAdditionsComputedValue(data.isFlatFee)}</td>
                <td className="w-50"><span
                    className="currency">GHS</span> {formatter.accounting.formatMoney(data.figure, " ")}
                </td>
            </tr>
        </Fragment>
    );
}

export default InvoiceAdditionItem;
