import React from "react";
import {Fragment} from "react";
import * as formatter from "../../misc/number_formatter";

const OrderItems = ({data}) => {
    const itemSumm = (quantity, unitPrice) => {
        return quantity * unitPrice
    }
    return (
        <Fragment>
            <tr>
                <td>x{data.item.quantity}</td>
                <td>{data.item.name}</td>
                <td><span
                    className="currency">{data.order.currency}</span>{formatter.accounting.formatMoney(itemSumm(data.item.quantity, data.item.unitPrice), " ")}
                </td>
            </tr>
        </Fragment>
    );
}

export default OrderItems;
