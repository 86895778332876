import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import api from "../network/services/api";
import quick_models, {dateFormatter} from "../network/models/quick_models";
import Loader from 'react-loaders';
import AgentMeta from "./AgentDetails";
import loaderImage from '../assets/img/hubtel_loader.gif';
import Section from "./Section";
import EmptyState from "./EmptyState";


const Home = () => {
    let {orderId, businessId} = useParams();
    const [isOrderLoading, setIsOrderLoading] = useState(true);
    const [isStatusLoading, setIsStatusLoading] = useState(true);
    const [isErrorOrder, setIsErrorOrder] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);


    const [deliveryStatus, setDeliveryStatus] = useState({});
    const [order, setOrder] = useState({});

    useEffect(() => {
        let b = quick_models.deliveryStatusRequester;
        b.orderId = orderId;
        api.getDeliveryStatus(b).then(response => {
            setDeliveryStatus(response.data);
            setIsStatusLoading(false);
        }).catch(error => {
            setIsStatusLoading(false);
            setIsErrorStatus(true);
        });

        let a = quick_models.orderRequest;
        a.businessId = businessId;
        a.orderId = orderId;
        api.getOrders(a).then(response => {
            setOrder(response.data);
            setIsOrderLoading(false);
        }).catch(error => {
            console.log(error);
            setIsErrorOrder(true);
            setIsOrderLoading(false);
        })

    }, []);

    let statuses = {...quick_models.deliveryResponse, ...deliveryStatus};
    let orderResponse = {...quick_models.orderResponse, ...order};

    return (
        <>
            <div className="wrapper main-wrapper">
                <div className="container">
                    <div className="row justify-content-center align-items-center" style={{minHeight: 400}}>
                        {
                            isOrderLoading ?
                                <div className="text-center align-middle py-5">
                                    <img src={loaderImage} width={50} className="img-fluid"/>
                                </div> :
                                <>
                                    {
                                        (isErrorStatus || isErrorOrder) ? <EmptyState/> :
                                            <div className="col-md-8 col-lg-4">
                                                <div className="tracker-wrapper">
                                                    <div className="container">
                                                        {
                                                            isOrderLoading ? "" :
                                                                <AgentMeta data={{
                                                                    title: orderResponse.data.BusinessName,
                                                                    subTitle: orderResponse.data.TotalAmountDue,
                                                                    image: orderResponse.data.LogoUrl
                                                                }}/>
                                                        }

                                                        <div className="text-right">
                                                        </div>
                                                        <small>{dateFormatter(orderResponse.data.OrderDate)}</small>

                                                        <div className="item">
                                                            {
                                                                isStatusLoading ?
                                                                    <Loader type="ball-grid-pulse" active
                                                                            color="red"/> :
                                                                    <div id="timeline">
                                                                        {
                                                                            quick_models.orderDeliveryTrackingSteps(statuses.data.Agent.Name,
                                                                                orderResponse.data.BusinessName, statuses.data.VerificationCode).map((item, index) => {
                                                                                return (
                                                                                    <Section key={index}
                                                                                             data={{
                                                                                                 index,
                                                                                                 item,
                                                                                                 statuses,
                                                                                                 orderResponse
                                                                                             }}/>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-sm-8 mt-4">
                            <div className="text-center pt-3 mx-5 mt-10">
                                <p className="" style={{color: "#c9d1da", fontSize: 13}}>Do more from your phone,
                                    shopping,
                                    payment orders,
                                    reciepts and rewards. All in one app.</p>
                                <div>
                                    <a href="#" className="text-decoration-none">
                                        <i className="fab fa-app-store mr-2"
                                           style={{fontSize: 27, color: "#c9d1da"}}/>
                                    </a>
                                    <a href="#" className="text-decoration-none">
                                        <i className="fab fa-google-play" style={{fontSize: 27, color: "#c9d1da"}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
