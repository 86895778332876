import React from "react";
import loaderImage from "../assets/img/hubtel_loader.gif";

const HLoader = () => {
    return (
        <>
            <div className="text-center align-middle py-5">
                <img src={loaderImage} width={50} className="img-fluid"/>
            </div>
        </>
    );
}

export default HLoader;
