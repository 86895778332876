import React, {useEffect, useState} from "react";
import giftImage from '../assets/img/gift.png';
import quick_models, {dateFormatter} from "../network/models/quick_models";
import api from "../network/services/api";
import {Link, useParams} from "react-router-dom";
import OrderItems from "./order/OrderItem";
import * as formatter from "../misc/number_formatter";
import loaderImage from '../assets/img/hubtel_loader.gif';
import ReportImage from '../assets/img/ask_help.svg';


import trackIcon from "../assets/img/status_check.svg"
import rate from '../assets/img/rate.svg';
import EmptyState from "./EmptyState";
import InvoiceAdditionItem from "./order/InvoiceAdditionsItem";
import {authToken, orderStatus} from "../misc/helpers";
import IssueModal from "./modals/Issues";
import TrackModel from "./modals/Track";
import RateModal from "./modals/Rate";
import PointRedemption from "./modals/PointRedemption";
import FailedOrder from "./FailedOrder";


const OrderHome = ({type}) => {
    let {orderId} = useParams();
    const [isOrderLoading, setIsOrderLoading] = useState(true);
    const [order, setOrder] = useState({});
    const [isErrorOrder, setIsErrorOrder] = useState(false);
    const [isErrorStatus, setIsErrorStatus] = useState(false);


    useEffect(() => {

        api.getSingleOrder(orderId).then(response => {
            setOrder(response.data);
            setIsOrderLoading(false);
            setIsErrorStatus(false);
        }).catch(error => {
            setIsErrorOrder(true);
            setIsOrderLoading(false);
            setIsErrorStatus(true);
        })
    }, []);

    let orderResponse = {...quick_models.orderResponse, ...order};
    let trackingStatusData = quick_models.orderTrackingBody;
    let paymentResponse = {...quick_models.payment, ...orderResponse.data.payment};


    const showIcons = () => {
        if (orderResponse.data.status.toLowerCase() === "paid") {
            return "block"
        } else {
            return "none"
        }
    }


    return (
        <>
            <div className="wrapper main-wrapper" style={{marginTop: 0}}>
                <div className="container">
                    <div className="row justify-content-center align-items-center" style={{minHeight: 400}}>
                        {
                            isOrderLoading ?
                                <div className="text-center align-middle py-5">
                                    <img src={loaderImage} width={50} className="img-fluid"/>
                                </div> :
                                <>
                                    {
                                        (isErrorStatus) ? <EmptyState/> :
                                            <div className="col-md-8 col-lg-4">
                                                <div className="reciept-offline" style={{marginTop: 30}}>

                                                    {
                                                        orderStatus(orderResponse.data.status) === "success" ?
                                                            <div className="pt-4 px-3 px-md-4 pb-4 bg-white">

                                                                <div className="">
                                                                    <div className="stream--orders mb-0 border-0">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="info">
                                                                                    <h6 className="font-weight-bold mb-0">{orderResponse.data.businessName}</h6>
                                                                                    <p className="text-muted mb-0">{orderResponse.data.businessMobileNumber}</p>
                                                                                    <p className="text-muted mb-0">{dateFormatter(orderResponse.data.orderDate)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="">
                                                                                <img src={orderResponse.data.logoUrl}
                                                                                     className="img-fluid" width="50"/>
                                                                            </div>
                                                                        </div>

                                                                        <div className=" mt-3">

                                                                            <div className="info">
                                                                                <div
                                                                                    className="d-flex justify-content-between">
                                                                                    <p className=" text-muted mb-0">Receipt
                                                                                        #</p>
                                                                                    <p className="text-muted mb-0">{orderResponse.data.orderNumber}</p>
                                                                                </div>
                                                                                <div
                                                                                    className="d-flex justify-content-between">
                                                                                    <p className="text-muted mb-0">Customer
                                                                                        Number</p>
                                                                                    <p className="text-muted mb-0">{orderResponse.data.customerMobileNumber}</p>
                                                                                </div>

                                                                                <div
                                                                                    className="d-flex justify-content-between">
                                                                                    <p className="text-muted mb-0">Customer
                                                                                        Name </p>
                                                                                    <p className="text-muted mb-0">{orderResponse.data.customerName}</p>
                                                                                </div>

                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center mt-1">
                                                                                    <p className="text-muted mb-0">Status </p>
                                                                                    <span
                                                                                        className={"status status-" + orderStatus(orderResponse.data.status)}><span
                                                                                        className="badge"></span></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="saved-order">
                                                                        <table
                                                                            className="table fixed_header mb-0 table-borderless">
                                                                            <thead className="border-bottom">
                                                                            <tr>
                                                                                <th scope="col"
                                                                                    className="pr-0 text-dark">Qty
                                                                                </th>
                                                                                <th scope="col"
                                                                                    className="pr-0 text-dark">Description
                                                                                </th>
                                                                                <th scope="col"
                                                                                    className="pr-0 text-right text-dark">Amount
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody className="border-bottom">
                                                                            {orderResponse.data.orderItems.map((item, index) => {
                                                                                return (<OrderItems key={index}
                                                                                                    data={{
                                                                                                        item: item,
                                                                                                        order: orderResponse.data
                                                                                                    }}/>);
                                                                            })}
                                                                            </tbody>
                                                                            <tfoot>
                                                                            <tr>
                                                                                <td className="text-muted w-100">Subtotal</td>
                                                                                <td className="w-50"><span
                                                                                    className="currency">{orderResponse.data.currency}</span>
                                                                                    {formatter.accounting.formatMoney(orderResponse.data.subtotal, " ")}
                                                                                </td>
                                                                            </tr>

                                                                            {orderResponse.data.invoiceAdditions.map((item, index) => {
                                                                                return (<InvoiceAdditionItem key={index}
                                                                                                             data={item}/>);
                                                                            })}
                                                                            <tr>
                                                                                <td className=" w-50">
                                                                <span className="font-wieght-bold"
                                                                      style={{
                                                                          fontSize: 22,
                                                                          fontWeight: "bold"
                                                                      }}> Total </span></td>
                                                                                <td className="font-wieght-bold w-50">
                                                                  <span className="font-wieght-bold "
                                                                        style={{fontSize: 22, fontWeight: "bold"}}>
                                                                    <span
                                                                        className="currency">{orderResponse.data.currency}</span>
                                                                      {formatter.accounting.formatMoney(orderResponse.data.totalAmountDue, " ")}
                                                                  </span></td>
                                                                            </tr>

                                                                            {
                                                                                // Show Rewards if it's a consumer receipt
                                                                                type.isConsumerReceipt ?
                                                                                    <>
                                                                                        <tr className="border-top">
                                                                                            <td className="text-muted w-100"
                                                                                                valign="middle">
                                                                                                <div
                                                                                                    className="d-flex align-items-center">
                                                                                <span className="mb-1 mr-1">  <img
                                                                                    src={giftImage}
                                                                                    className="img-fluid"
                                                                                    width="20"/></span>
                                                                                                    <span
                                                                                                        className="text-dark"> Reward Points</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="w-50">{orderResponse.data.customerReward + " gpts"}</td>
                                                                                        </tr>
                                                                                        <tr className="border-top">
                                                                                            <td className="w-100">
                                                                                                <div
                                                                                                    className="d-flex justify-content-around">
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                    : null
                                                                            }

                                                                            </tfoot>
                                                                        </table>
                                                                    </div>
                                                                    {
                                                                        // Show Receipt Options if it's a consumer receipt
                                                                        type.isConsumerReceipt ?
                                                                            <div className="row">
                                                                                <div
                                                                                    className="col-4 text-center position-relative"
                                                                                    data-target="#trackStatus"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        display: showIcons()
                                                                                    }}
                                                                                    data-toggle="modal">
                                                                                    <img src={trackIcon}
                                                                                         style={{width: 25}}/>
                                                                                    <p className="mt-2 mb-0">Track</p>
                                                                                </div>
                                                                                <div
                                                                                    className="col-4 text-center position-relative"
                                                                                    data-target="#ratings"
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        display: showIcons()
                                                                                    }}
                                                                                    data-toggle="modal">
                                                                                    <img style={{width: 25}}
                                                                                         src={rate}/>
                                                                                    <p className="mt-2 mb-0">Rate</p>
                                                                                </div>
                                                                                <div
                                                                                    className="col-4 text-center  position-relative"
                                                                                    data-toggle="modal"
                                                                                    style={{cursor: "pointer"}}
                                                                                    data-target="#reportIssue">
                                                                                    <img style={{width: 25}}
                                                                                         src={ReportImage}/>
                                                                                    <p className="mt-2 mb-0">Report</p>
                                                                                </div>

                                                                            </div> : null
                                                                    }
                                                                </div>

                                                            </div> :
                                                            <FailedOrder
                                                                data={{
                                                                    orderDetails: orderResponse.data,
                                                                    payment: paymentResponse
                                                                }}/>
                                                    }

                                                    <div className="text-center pt-3 mx-5">
                                                        <p className="" style={{color: "#c9d1da", fontSize: 13}}>Do more
                                                            from your phone, shopping,
                                                            payment orders,
                                                            receipts and rewards. All in one app.</p>
                                                        <div>
                                                            <a href="#" className="text-decoration-none">
                                                                <i className="fab fa-app-store mr-2"
                                                                   style={{fontSize: 27, color: "#c9d1da"}}></i>
                                                            </a>
                                                            <a href="#" className="text-decoration-none">
                                                                <i className="fab fa-google-play"
                                                                   style={{fontSize: 27, color: "#c9d1da"}}></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="text-center small p-3"
                                                         style={{color: "#c9d1da"}}>©2005-2020. Hubtel
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
            {
                isOrderLoading ? null : <>
                    {<PointRedemption data={orderResponse.data}/>}
                    {<IssueModal data={orderResponse.data}/>}
                    {<TrackModel data={{
                        data: trackingStatusData.data,
                        orderDetails: orderResponse.data,
                        payment: paymentResponse
                    }}/>}
                    {<RateModal data={orderResponse.data}/>}
                </>
            }
        </>
    );
}

export default OrderHome;
