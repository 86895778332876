import React from "react";

const DataNotAvailableBlock = () => {
    return (
        <>
            <div className="empty-block">
                <div className="empty-data-head"></div>
                <div className="empty-data-body"></div>
            </div>
        </>
    )
};

export default DataNotAvailableBlock;
