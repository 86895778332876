import axios from 'axios';
import {authToken} from "../misc/helpers";

const BASEURL = "https://hubtelappproxy.hubtel.com/";

let instance = axios;

instance.defaults.headers.post['Content-Type'] = 'application/json-patch+json';
instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.post['Access-Control-Allow-Methods'] = '*';
instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.defaults.headers.common['Authorization'] = "Bearer " + authToken;
instance.defaults.headers.post['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
axios.defaults.crossDomain = true;


const getRequestType = (method = "GET", basePath, relativePath, data, authorization) => {

    switch (method) {
        case "GET":
            return instance.get(basePath + relativePath);
        case "POST":
            return instance.post(basePath + relativePath, data);
        case "PUT":
            return instance.put(basePath + relativePath, data);
        default:
            return instance.get(basePath + relativePath);
    }
};

const makeRequest = (method = "GET", path = "", baseUrl = "", data = {}, setAuthorization = false) => {
    baseUrl = baseUrl.length !== 0 ? baseUrl : BASEURL;
    return getRequestType(method, baseUrl, path, data, setAuthorization)
};

const requestMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT"
};

export default {
    methods: requestMethods,
    makeRequest: makeRequest
};
