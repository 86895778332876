import React from 'react';
import './App.css';
import Home from "./components/Home";
import {
    BrowserRouter as Router,
    Route, Switch
} from 'react-router-dom';
import EmptyState from "./components/EmptyState";
import OrderHome from "./components/OrderHome";

function App() {
    return (
        <Router>
            <Switch>
                {/*Receipt View for Consumers*/}
                <Route exact path="/:orderId" render={() => <OrderHome type={{isConsumerReceipt: true}}/>}/>
                {/*Receipt View for Producers*/}
                <Route exact path="/p/:orderId" render={() => <OrderHome type={{isConsumerReceipt: false}}/>}/>
                <Route exact path="/track/:orderId/:businessId" render={() => <Home/>}/>
                <Route exact path="/" render={() => <EmptyState/>}/>
            </Switch>
        </Router>

    );
}

export default App;
