import React, {useEffect, useState} from "react";
import api from "../../network/services/api";
import quick_models from "../../network/models/quick_models";
import EmptyState from "../EmptyState";
import {getAuth, setAuth} from "../../misc/helpers";
import HLoader from "../HLoader";

const OtpModal = ({data}) => {

    const [otp, setOtp] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [otpSuffix, setOtpSuffix] = useState("");
    const [viewState, setViewState] = useState("default")
    const [errorResponse, setErrorResponse] = useState("")
    const [msg, setMsg] = useState("")

    useEffect(() => {
        sendOtp()
        const timer = setTimeout(() => {
            setMsg("Dial *713*90# with " + data.order.customerMobileNumber + " to see the 4-digit OTP message to log in. (Ghana only)")
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    let otpResponse = {...quick_models.signInResponse, ...otp}

    const sendOtp = () => {
        let datax = {"msisdn": data.order.customerMobileNumber}
        api.signIn(datax).then(response => {
            setOtp(response.data)
            setIsLoading(false)
            setViewState("verify")
        }).catch(error => {
            setIsLoading(true)
        })
    }


    const sendOtpVerification = () => {
        let request = quick_models.otpVerificationRequest
        //
        request.msisdn = data.order.customerMobileNumber
        request.otpCode = otpResponse.data.otpPrefix + "-" + otpSuffix
        request.requestId = otpResponse.data.requestId
        setViewState("default")
        api.verifyOtp(request).then(response => {
            setAuth(response.data)

            //
            switch (data.type) {
                case "report":
                    sendReport(data.order)
                    break;
                case "feedback":
                    sendCustomerFeedback(data.order)
                    break;
                case "redeempoint":
                    redeemPoint(data.order)
                    break;
                default:
            }

        }).catch(error => {
            setViewState("error")
            setErrorResponse(error.response.data.developer_message)
        })
    };


    const sendReport = (order) => {
        order.consumerFeedback = data.selectedIssue
        order.authToken = getAuth().data.token
        api.sendReport(order).then(response => {
            setViewState("message")
        }).catch(error => {
            setViewState("error")
            setErrorResponse(error.response.data.developer_message)
        })
    }

    const redeemPoint = (order) => {
        let request = quick_models.pointRedemptionRequester

        request.authToken = getAuth().data.token
        request.orderId = order.Id

        api.redeemPoints(request).then(response => {
            setViewState("message")
        }).catch(error => {
            setViewState("error")
            setErrorResponse(error.response.data.developer_message)
        })
    }


    const sendCustomerFeedback = (order) => {
        let request = quick_models.customerFeedbackRequester

        request.authToken = getAuth().data.token
        request.busnessId = order.businessId
        request.orderId = order.id
        request.feedback = data.feedback.comment
        request.rate = data.feedback.rating

        api.sendCustomerFeedback(request).then(response => {
            setViewState("message")
        }).catch(error => {
            setViewState("error")
            setErrorResponse(error.response.data.developer_message)
        })

    }

    const viewDisplay = () => {
        switch (viewState.toLowerCase()) {
            case "verify":
                return (<div className="col-md-12 ">
                    <div className="text-center">

                        <div className="mb-3">
                            <h5 className="">Verify your phone number</h5>
                            <div
                                className="d-flex align-items-center justify-content-center flex-wrap">
                                Please enter the 4 digit OTP sent to &nbsp; <span
                                className="font-weight-bold">{data.order.customerMobileNumber}</span>.
                            </div>
                        </div>

                        {!isLoading ?
                            <div className="row justify-content-center">

                                <div className="col-md-11">
                                    <div className="my-4 d-none">
                                        <input type="number" className="form-control p-2" required=""
                                               placeholder="4456" autoFocus=""/>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-6 col-md-4">
                                            <div className="enterOTP my-2 pb-1">
                                                <div className="d-flex align-items-center">
                                                    <p className="m-0">{otpResponse.data.otpPrefix} </p>
                                                    <p className="m-0 px-2"> - </p>
                                                    <input type={"number"} onChange={(e) => {
                                                        setOtpSuffix(e.target.value)
                                                    }} maxLength="4" className="form-control" pattern={"[0-9]{1,4}"}
                                                           autoFocus="" required/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="my-4">
                                        <button
                                            onClick={() => {
                                                sendOtpVerification()
                                            }}
                                            className="btn btn-primary font-weight-bold">
                                            Verify
                                        </button>
                                    </div>
                                    <div>
                                        <p>{msg}</p>
                                    </div>
                                </div>
                            </div> : null}
                    </div>
                </div>);
            case "message":
                return (
                    <>
                        {message(data.type)}
                    </>
                );
            case "error":
                return (
                    <div className="modal-body">
                        <div className="px-4 py-4">
                            <h5 className="font-weight-bold">An Error Occurred</h5>
                            <div className="mt-4">
                                <p>{errorResponse}</p>
                            </div>
                            <div className="mt-4 text-right">
                                <a href="#" data-toggle="modal" data-dismiss="modal"
                                   className="btn btn-primary">Okay</a>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <HLoader/>
        }
    }

    const message = (type) => {
        let title, message
        switch (type) {
            case "report":
                title = "Report Submitted";
                message = "Your complaint: (" + data.selectedIssue + ") has been submitted successfully.";
                break;
            case "feedback":
                title = "Feedback Submitted";
                message = "Thank you for your feedback.";
                break;
            case "redeempoint":
                title = "Points Redeemed";
                message = "Your points have been redeemed successfully";
                break;
            default:
        }

        return (
            <div className="modal-body">
                <div className="px-4 py-4">
                    <h5 className="font-weight-bold">{title}</h5>

                    <div className="mt-4">
                        <p>{message}</p>
                    </div>
                    <div className="mt-4 text-right">
                        <a href="#" data-toggle="modal" data-dismiss="modal"
                           className="btn btn-primary">Okay</a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="modal-body">
                <div className="mt-4" style={{minHeight: 200}}>
                    {viewDisplay()}
                </div>
            </div>
        </>
    );
}

export default OtpModal;
