import React, {Fragment, useState} from "react";
import okayOutline from '../../assets/img/okay-outline.png';
import badOutline from '../../assets/img/bad-outline.png';
import greatOutline from '../../assets/img/great-outline.png';
import okayFull from '../../assets/img/okay-full.png';
import badFull from '../../assets/img/bad-full.png';
import greatFull from '../../assets/img/great-full.png';
import quick_models from "../../network/models/quick_models";
import HLoader from "../HLoader";
import OtpModal from "./Otp";


const RateModal = ({data}) => {
    const [iconSelected, setIconSelected] = useState("");
    const [iconSelectedState, setIconSelectedState] = useState("outline");
    const [userFeedBack, setUserFeedback] = useState("")
    const [viewState, setViewState] = useState("default")


    const getSelectedRate = (state) => {
        switch (state) {
            case "bad":
                return 1
            case "okay":
                return 2
            case "great":
                return 3
            default:
                return 0
        }
    }

    const setIconState = (icon, state) => {
        console.log("new", iconSelected, iconSelectedState)
        console.log("old", icon, state)

        setIconSelected(icon)
        if (state === "outline") {
            setIconSelectedState("full")
        } else {
            setIconSelectedState("outline")
        }
    }

    const getImage = (selectedIcon, selectedState, icon) => {

        let match = "";
        if (selectedIcon === icon) {
            match = selectedIcon + selectedState
        } else {
            match = icon + "outline"
        }

        switch (match) {
            case "badoutline":
                return badOutline;
            case "okayoutline":
                return okayOutline;
            case "greatoutline":
                return greatOutline;
            case "badfull":
                return badFull;
            case "okayfull":
                return okayFull;
            case "greatfull":
                return greatFull;
            default:
                return ""
        }
    }

    const displayViewState = () => {
        switch (viewState.toLowerCase()) {
            case "default":
                return (
                    <div className="modal-body">
                        <div className="px-4 py-4">
                            <h6 className="font-weight-bold text-center">{data.businessName}</h6>
                            <p className="text-center">How was your experience?</p>

                            <div className="mt-4">

                                <div className="d-flex justify-content-around">
                                    {
                                        quick_models.ratings.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     onClick={() => {
                                                         setIconState(item.toLowerCase(), iconSelectedState)
                                                     }}
                                                     className={"text-center rating-smiley " + item + "pointer"}>
                                                    <img
                                                        src={getImage(iconSelected, iconSelectedState, item.toLowerCase())}
                                                        className="img-fluid d-block mb-2" width="60"/>
                                                    {item}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className="form-group mt-5">
                                    <div>
                                <textarea onChange={(e) => {
                                    setUserFeedback(e.target.value)
                                }} className="form-control" cols="30" rows="6"
                                          placeholder="Add a message"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4 text-right">
                                    <button
                                        onClick={() => {
                                            if (iconSelectedState === "full") {
                                                setViewState("otp")
                                            }
                                        }}
                                        className={iconSelectedState !== "full" ? "btn btn-primary disabled" : "btn btn-primary"}
                                    >Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "otp":
                return (
                    <OtpModal data={{
                        order: data,
                        selectedIssue: "",
                        feedback: {rating: getSelectedRate(iconSelected), comment: userFeedBack},
                        type: "feedback"
                    }}/>
                );
            default:
                return (<HLoader/>);
        }

    }

    return (
        <Fragment>
            <div className="modal fade" id="ratings" tabIndex="-1" role="dialog" aria-labelledby="savedOrderLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        {
                            displayViewState()
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default RateModal;
