import React, {Fragment, useState} from "react";
import * as formatter from "../misc/number_formatter";
import quick_models from "../network/models/quick_models";
import OtpModal from "./modals/Otp";

const FailedOrder = ({data}) => {
    const [issue, setIssue] = useState("");
    const [writtenIssue, setWrittenIssue] = useState("");
    const [showOtp, setShowOtp] = useState(false);

    return (
        <>
            <div className="pt-4 px-4 pb-1 border bg-white">
                <div className=" pb-4 d-flex  justify-content-center align-items-center">

                    <div className=" d-flex align-items-center">

                        <div>
                            <img src={data.orderDetails.logoUrl} alt="" width="40" className="rounded-circle"/>
                        </div>
                        <div className="ml-3">
                            <p className="mb-0 text-muted">You bought from</p>
                            <h6 className="mb-1 font-weight-bold">{data.orderDetails.businessName}</h6>
                        </div>
                    </div>

                    <div className="text-right">
                    </div>

                </div>
                <div className=" pb-4 d-flex  justify-content-between align-items-center">
                    <div className="text-center">
                        <h1 className="mt-1 mb-1 font-weight-bold"><span className="currency">{data.orderDetails.currency}</span>
                            {formatter.accounting.formatMoney(data.orderDetails.totalAmountDue, " ")}
                        </h1>
                    </div>
                    <div className="text-right">
                      <span className="status status-failed">
                        <span className="badge"></span>
                      </span>
                    </div>
                </div>


                <div className="p-4 bg-light text-dark border rounded">
                    <h6 className="font-weight-bold mt-0">Reason</h6>

                    <p className="">
                        {
                            data.payment.providerDescription
                        }
                    </p>
                </div>

                <div className=" mt-4">
                    <div className="col-md-12 ">
                        <div className="mb-4">
                            <h6 className="font-weight-bold mt-4">Tell us what happened</h6>
                            {
                                quick_models.failedIssuesList.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <div
                                                className=" form-check mt-2 d-block m-0 border-bottom py-3 h6 font-weight-normal text-decoration-none text-dark">
                                                <input type="radio" className="form-check-input" name="exampleRadios"
                                                       onChange={() => {
                                                           setIssue(item)
                                                       }}
                                                       id={"exampleCheck" + index}/>
                                                <label className="form-check-label text-dark"
                                                       htmlFor={"exampleCheck" + index}>{item}</label>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }

                            <h6 className="font-weight-bold mt-4 mb-2">Other</h6>
                            <textarea type="text" className="form-control" onChange={(e) => {
                                setWrittenIssue(e.target.value)
                            }} placeholder="Type your message here"></textarea>

                            <button className={
                                issue.length === 0 && writtenIssue.length === 0 ? "btn btn-primary w-100 mt-4 text-white disabled" :
                                    "btn btn-primary w-100 mt-4 text-white"
                            }
                                    data-target={issue.length === 0 && writtenIssue.length === 0 ? "" : "#report"}
                                    onClick={() => {
                                        setShowOtp(true)
                                    }}
                                    data-toggle="modal">
                                Report
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal fade" data-backdrop="static" id="report" tabIndex="-1" role="dialog"
                 aria-labelledby=""
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{minHeight: 300}}>
                        <button type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={() => {
                                    setShowOtp(false)
                                }}
                                aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            {
                                showOtp ?
                                    <OtpModal data={{
                                        order: data.orderDetails,
                                        selectedIssue: issue.length === 0 ? writtenIssue : issue,
                                        feedback: {rating: 0, comment: ""},
                                        type: "report"
                                    }}/>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FailedOrder;
