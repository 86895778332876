import React from "react";

const Block = ({data}) => {

    const showImage = () => {
        if (data.image === "") {
            return <div
                className="rounded-circle mt-2 mr-3 ml-3">
                <i className="fas fa-user-circle text-muted fa-2x"></i>
            </div>
        } else {
            return <img src={data.image} alt="" width="30"
                        className="rounded-circle ml-2 mr-3"/>
        }
    }

    return (
        <>
            <div
                className="border bg-white pb-2 pt-2 pl-2 pr-2">
                <div className="row align-items-center">
                    <span className="col-8">
                        <div className="d-flex align-items-center">
                            {showImage()}
                            <span className="">
                                <small
                                    className="text-muted">{data.subTitle}</small>
                                <div>{data.title}</div>
                            </span>
                        </div>
                    </span>
                    <div className="col-4 text-right">
                        <a href={"tel:" + data.subTitle}>
                            <span className="mr-2 ml-2">
                                <i className="fas fa-phone fa-2x" style={{fontSize: 27, color: "#c9d1da"}}></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Block;
