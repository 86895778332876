import React from "react";
import * as formatter from "../misc/number_formatter";

const AgentMeta = ({data}) => {
    return (
        <>
            <div className="border bg-white px-4 py-4 mb-4">
                <div className="row align-items-center">
                    <div className="col-6">
                        <div className="align-self-center">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <img src={data.image} alt="" width="40"
                                         className="rounded-circle mr-2 "/>
                                </li>
                                <li className="list-inline-item">
                                    <ul className="list-unstyled">
                                        <li>
                                            <small className="mb-0 text-muted">You bought from</small>
                                        </li>
                                        <li>
                                            <h6 className="mb-1 font-weight-bold">{data.title}</h6>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="ml-auto text-right">
                            <small className="mb-0 text-muted">Total Charge</small>
                            <h6 className="mb-1 font-weight-bold">{"GHS " + formatter.accounting.formatMoney(data.subTitle, " ")}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentMeta;
