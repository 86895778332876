import quick_models, {dateFormatter} from "../network/models/quick_models";
import * as formatter from "./number_formatter";

const assignedMeta = {
    title: "Ready for Pick Up",
    message: "Your order has been assigned to a careforce rider"
};

const acceptedMeta = {
    title: "Accepted",
    message: "Your order has been accepted by rider and enroute to pick up"
};

const pickedUpMeta = {
    title: "Picked Up",
    message: "Your order has been picked up by"
};

const deliveredMeta = {
    title: "Delivered",
    message: "You will have to provide an SMS verification code to rider."
};

const confirmedMeta = {
    title: "Delivered",
    message: "Your order has been delivered by"
};

export const defaultMeta = {
    title: "",
    message: ""
};

const tokenStorageKey = "hubtelToken";
export const setAuth = (token) => {
    localStorage.setItem(tokenStorageKey, JSON.stringify(token))
}

export const getAuth = () => {
    let authDetails = JSON.parse(localStorage.getItem(tokenStorageKey))
    return {...quick_models.verifiedOtpResponse, ...authDetails}
}

export const deliveryStates = ["Assigned", "Accepted", "PickedUp", "Delivered", "Confirmed"];
export const deliveryStatesMeta = [assignedMeta, acceptedMeta, pickedUpMeta, deliveredMeta, confirmedMeta];

export const getStatusMeta = (status) => {
    let index = deliveryStates.indexOf(status);
    if (index < 0) {
        return deliveredMeta[0];
    }
    return deliveredMeta[index];
};

export const getStatusMessage = (status) => {
    switch (status) {
        case "Assigned":
            return assignedMeta.message;
        case "Accepted":
            return acceptedMeta.message;
        case "PickedUp":
            return pickedUpMeta.message;
        case "Delivered":
            return deliveredMeta.message;
        case "Confirmed":
            return confirmedMeta.message;
        default:
            return assignedMeta.message;
    }
};

export const getStatusMetaTitle = (status) => {
    switch (status) {
        case "Assigned":
            return assignedMeta.title;
        case "Accepted":
            return acceptedMeta.title;
        case "PickedUp":
            return pickedUpMeta.title;
        case "Delivered":
            return deliveredMeta.title;
        case "Confirmed":
            return confirmedMeta.title;
        default:
            return assignedMeta.title;
    }
};


export const getDeliveryStateTime = (data = quick_models.deliveryResponse.data.DeliveryStates, currentStatus) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].State === currentStatus) {
            return data[i].CreatedAt
        }
    }
};

export const getCurrentState = (data = quick_models.deliveryResponse.data.DeliveryStates, currentStatus) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].State === currentStatus) {
            return data[i].State
        }
    }
};

export const isActive = (data = quick_models.deliveryResponse.data.DeliveryStates, currentStatus) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].State === currentStatus) {
            return data[i].State === currentStatus;
        }
    }
};


const getWalletInscription = (paymentStatusResponse, order) => {
    switch (paymentStatusResponse.paymentType.toLowerCase()) {
        case "cash" : {
            return "Cash"
        }
        case  "card": {
            let message = paymentStatusResponse.card;
            return "Bank card ending with " + message.substring(message.length - 4, message.length)
        }
        case  "hubtel": {
            let message = order.customerMobileNumber;
            return "Hubtel wallet ending with " + message.substring(message.length - 4, message.length)
        }
        case  "momo":
        case "mobilemoney" : {
            let message = paymentStatusResponse.momoPhoneNumber;
            return "Mobile Money ending with " + message.substring(message.length - 4, message.length)
        }
        default:
            return ""
    }
}


const getPaymentProvider = (paymentStatusResponse) => {
    switch (paymentStatusResponse.paymentType.toLowerCase()) {
        case "cash":
            return "https://designs.hubtel.com/v3/app/dist/images/cash.png";
        case "card":
            if (paymentStatusResponse.scheme?.toLowerCase() === "visa") {
                return "https://designs.hubtel.com/v3/app/dist/images/visa.png";
            } else {
                return "https://designs.hubtel.com/v3/app/dist/images/Mastercard.png";
            }
        case "hubtel":
        case "hubtel-gh":
            return "https://hubtelpos-dev.s3.amazonaws.com/images/53778e45e8944a98aa8d9e988d19ece6.jpeg";
        case "momo":
        case "mobilemoney":
            switch (paymentStatusResponse.momoChannel.toLowerCase()) {
                case  "mtn-gh":
                case "mtn-gh-cbg":
                case "mtn_gh_rec":
                    return "https://hubtelpos-dev.s3.amazonaws.com/images/068dcf665c0043618f4adb6e440d3014.jpeg";
                case "tigo-gh":
                case "airtel":
                    return "https://hubtelpos-dev.s3.amazonaws.com/images/c5bea395835341c9a9a048791b5dce52.jpeg";
                case "vodafone":
                case "vodafone-gh-ussd":
                case "vodafone-gh":
                    return "https://hubtelpos-dev.s3.amazonaws.com/images/3545a98aaf7c4681abda70252910520e.jpeg";
                default:
                    return "";
            }
        default:
            return "";
    }
};

const statusMessage = (status, order) => {
    switch (status) {
        case "success":
            return "Your purchase of " + formatter.accounting.formatMoney(order.totalAmountDue, order.currency + " ") + " has been completed."
        case "pending":
            return "Your purchase of" + formatter.accounting.formatMoney(order.totalAmountDue, order.currency + " ") + " is pending."
        case "failed":
            return order.Note;
        default:
    }
}

const checkService = (order, paymentStatus) => {

    if (order.isProgrammableService) {
        if (paymentStatus.isSuccessful === true && order.isFulfilled) {
            return "success"
        } else if (paymentStatus.isSuccessful === false && order.status.toLowerCase() === "unpaid") {
            return "failed"
        } else if (paymentStatus.isSuccessful === false && order.status.toLowerCase() === "paid") {
            return "pending"
        } else {
            return ""
        }
    } else {
        if (paymentStatus.isSuccessful === true && order.status.toLowerCase() === "paid") {
            return "success"
        } else if (paymentStatus.isSuccessful === false && order.status.toLowerCase() === "unpaid") {
            return "failed"
        } else if (paymentStatus.isSuccessful === "" && order.status.toLowerCase() === "unpaid") {
            return "pending"
        } else {
            return ""
        }
    }

}

export const prepareOrderPaymentTracking = (order, paymentStatus) => {

    let orderTrackingSteps = quick_models.orderTrackingBody.data
    let purchase = formatter.accounting.formatMoney(order.totalAmountDue, order.currency + " ")
    orderTrackingSteps.map((item, index) => {
        if (index === 0) {
            orderTrackingSteps[index] = {
                title: "Creating your order",
                info: "",
                subTitle: "Your order of " + purchase + " has been created successfully.",
                image: "",
                message: "",
                deactivate: false,
                showBottom: false,
                text: dateFormatter(order.orderDate),
                status: "success"
            }
        } else if (index === 1) {
            orderTrackingSteps[index] = {
                title: "Processing your payments",
                info: "Wallet details",
                subTitle: paymentStatus.providerDescription,
                image: getPaymentProvider(paymentStatus),
                message: getWalletInscription(paymentStatus, order),
                deactivate: false,
                showBottom: true,
                text: dateFormatter(paymentStatus.startDate),
                status: orderStatus(paymentStatus.isSuccessful === true ? "success" : "failed")
            }
        } else if (index === 2) {
            orderTrackingSteps[index] = {
                title: "Completing your purchase",
                info: "Served by",
                subTitle: statusMessage(checkService(order, paymentStatus), order),
                image: order.logoUrl,
                message: order.businessName,
                deactivate: true,
                showBottom: checkService(order, paymentStatus) === "success",
                text: dateFormatter(order.orderDate),
                status: checkService(order, paymentStatus)
            }
        }
    });

    return orderTrackingSteps
}

export const trackerStatus = (order, paymentStatus) => {
    if (order.isProgrammableService) {
        if (order.isFulfilled && order.status.toLowerCase() === "paid") {
            return "success"
        } else if (!order.isFulfilled && order.status.toLowerCase() === "paid") {
            return "pending"
        } else if (!order.isFulfilled && paymentStatus.data.TransactionStatus === "failed" && order.status.toLowerCase() === "unpaid") {
            return "failed"
        } else {
            return ""
        }
    } else if (order.status.toLowerCase() === "paid") {
        return "success"
    } else {
        return "failed"
    }
}

export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9tb2JpbGVwaG9uZSI6IjIzMzU1MjkxNjYwMyIsImp0aSI6ImU4NzI5MWIzLWQ3ODItNDVhNC1hN2FiLWI0NmZjM2U5ZDMxNSIsIm5iZiI6MTU3MTg1MDAxMywiZXhwIjoxNjAzMzg2MDEzLCJpc3MiOiJodHRwOi8vaHVidGVsLmNvbSIsImF1ZCI6Imh0dHA6Ly9odWJ0ZWwuY29tIn0.uxzLixj2LaS_suIPmIWex80SBt_AHkk1heu75WO0xD4";


export const orderStatus = (status) => {
    if (status != null) {
        switch (status.toLowerCase()) {
            case "paid":
            case "success":
                return "success";
            case "unpaid":
            case "failed":
                return "failed";
            case "pending":
                return "pending";
            default:
                return "";
        }
    }
};



