import Requester from "../Requester";

const getDeliveryStatus = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/Orders/GetDeliveryStatus", "", data)
};

const getPayment = (receiptNumber) => {
    return Requester.makeRequest(Requester.methods.GET, "",
        "https://ordersapi.hubtel.com/api/v1/payments/receipt/" + receiptNumber, "")
};

const getSingleOrder = (orderId) => {
    // https://hubtelappproxy.hubtel.com/api/v1/Orders/receipt/c1c4510d4ea24fb8a80ea4a0cd3ac8cb
    return Requester.makeRequest(Requester.methods.GET, `api/v1/Orders/receipt/${orderId}`)
}

const getOrders = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/Orders/GetOrderByOrderAndBusinessId", "", data)
};

const getPaymentStatus = (orderId, businessId) => {
    return Requester.makeRequest(Requester.methods.GET, "api/v1/Orders/paymentstatus/" + orderId + "/" + businessId)
}

const signIn = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/account/signin", "", data)
}

const verifyOtp = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/account/verify", "", data)
}

const sendReport = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/Chat/reportissue", "", data)
}

const redeemPoints = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/PointRedemption/ManualRedemption", "", data)
};

const sendCustomerFeedback = (data) => {
    return Requester.makeRequest(Requester.methods.POST, "api/v1/Orders/customerfeedback", "", data)
}


export default {
    getDeliveryStatus: getDeliveryStatus,
    getOrders: getOrders,
    getPaymentStatus: getPaymentStatus,
    signIn: signIn,
    verifyOtp: verifyOtp,
    sendReport: sendReport,
    redeemPoints: redeemPoints,
    sendCustomerFeedback: sendCustomerFeedback,
    getPayment: getPayment,
    getSingleOrder: getSingleOrder
}
