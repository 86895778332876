import React, {Fragment, useState} from "react";
import quick_models from "../../network/models/quick_models";
import OtpModal from "./Otp";
import HLoader from "../HLoader";

const IssueModal = ({data}) => {
    const [issue, setIssue] = useState("");
    const [isIssueSelected, setIssueSelected] = useState(false);
    const [viewState, setViewState] = useState("default")


    const onIssueSelected = (selectedIssue) => {
        setIssue(selectedIssue)
        if (selectedIssue.length === 0) {
            setIssueSelected(false)
        } else {
            setIssueSelected(true)
        }
    }


    const viewDisplay = () => {
        switch (viewState.toLowerCase()) {
            case "default":
                return (
                    <div className="modal-body">
                        <div className=" mt-4">
                            <div className="col-md-12 ">
                                <div>
                                    <h5>Tell us what happened</h5>
                                    {
                                        quick_models.issuesList.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <a href="#"
                                                       onClick={
                                                           () => {
                                                               onIssueSelected(item)
                                                               setViewState("otp")
                                                           }
                                                       }
                                                       className="d-block m-0 border-top py-3 h6 font-weight-normal
                                                       text-decoration-none text-dark">
                                                        {item}
                                                    </a>
                                                </Fragment>
                                            )
                                        })
                                    }
                                    <a href="#" onClick={
                                        () => {
                                            setViewState("issue")
                                        }
                                    }
                                       className=" d-block m-0 border-top py-3 h6 font-weight-normal text-decoration-none text-dark">
                                        Other <i className="fas fa-angle-right float-right"></i>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                );
            case "issue":
                return (
                    <div className="modal-body">
                        <div className="mt-4">
                            <div className="col-md-12 ">
                                <div>
                                    <h5>Tell us what happened</h5>
                                    <textarea onChange={(e) => {
                                        onIssueSelected(e.target.value)
                                    }}
                                              className="form-control mt-4"
                                              placeholder="Give us a description of what happened"
                                              rows="6"></textarea>

                                    <div className="d-flex justify-content-between mt-4">
                                        <button onClick={() => {
                                            setViewState("default")
                                        }}
                                                className="btn btn-link d-block m-0 font-weight-bold text-decoration-none">
                                            BACK
                                        </button>
                                        <button onClick={() => {
                                            if (isIssueSelected) {
                                                setViewState("otp")
                                            }
                                        }}
                                                className={isIssueSelected ? "btn btn-primary d-block m-0 font-weight-bold text-decoration-none"
                                                    : "btn btn-primary d-block m-0 font-weight-bold text-decoration-none disabled"}
                                        >
                                            SEND
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "otp":
                return (
                    <OtpModal data={{
                        order: data,
                        selectedIssue: issue,
                        feedback: {rating: 0, comment: ""},
                        type: "report"
                    }}/>
                );
            default:
                return <HLoader/>
        }
    }

    return (
        <Fragment>
            <div className="modal fade" id="reportIssue" tabIndex="-1" role="dialog" aria-labelledby=""
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {viewDisplay()}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default IssueModal;
