import React from "react";

const Timeline = ({data}) => {
    const TimelineBottom = () => {
        return (
            <div className="bg-light p-2 mt-3">
                <span
                    className="text-muted d-block border-bottom pb-1">{data.info}</span>
                <div className="d-flex pt-2 align-items-center">
                    <img src={data.image}
                         className="img-fluid mr-2 object-fit-contain" width="30"/>
                    <p className="mb-0">{data.message}</p>
                </div>

            </div>
        );
    };

    return (
        <div className="timeline-item">
            <span className={"beam beam-" + data.status}></span>
            <>
                <span className="small text-muted">{data.text}</span>
                <p className="lead mb-0">{data.title}</p>
                <p className="small">{data.subTitle}</p>
                {
                    data.showBottom ? <TimelineBottom/> : null
                }
            </>
        </div>
    );
};

export default Timeline;
